import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ClassNamesExport from 'classnames'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { CONFIG } from 'src/cms/config'
import { ACCOUNT_TYPE_ARRAY, AUTHORI_SEARCH_PATHS } from 'src/common/constants'
import styled, { css, type ITheme } from 'src/common/styles'
import { useListCompanyAddonByCompanyId } from 'src/services'
import { CompanyStatusChecker } from 'src/containers/domains/app/elements/CompanyStatusChecker'
import { getLastAuthoriSearchedUrlFromCookie } from 'src/common/helper/cookieManager'
import { CompanyAccountChecker } from 'src/containers/domains/app/elements/CompanyAccountChecker'
import { emmbedAnlTags } from 'src/lib/amplify/analytics/helper'
import type { SerializedStyles } from '@emotion/react'
import { BetaChecker } from './BetaChecker'
import { UserRoleChecker } from './RoleChecker'

type StyleProps = {
  isOpen: boolean
}

type Props = StyleProps & {
  handleToggleDrawer: (bool: boolean) => void
}

const ScSidebar = styled.aside<StyleProps>`
  grid-area: nav;
  width: ${({ isOpen }: StyleProps): string => (isOpen ? `240px` : '44px')};
  height: 100vh;
  padding-top: 80px;
  background-color: #222d32;
  position: fixed;
  top: 0;
  left: 0;
`

const ScSidebarInner = styled.div<StyleProps>`
  display: ${({ isOpen }: StyleProps): string => (isOpen ? `block` : 'none')};
  overflow-y: scroll;
  height: calc(100% - 32px);
  padding-bottom: 24px;
`

const ScLabel = styled.div`
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.875rem;
  padding: 12px 32px;
`

const ScLink = styled(Link)`
  text-decoration: none;
  font-size: 0.875rem;
  font-weight: bold;
  color: #fff;
  display: block;
  padding: 12px 32px 12px 48px;
  position: relative;
  transition: 0.2s;
  &:link,
  &:visited {
    color: #fff;
  }
  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
    text-decoration: none;
  }
  ${({ theme }: { theme: ITheme }): SerializedStyles => css`
    &.is-current {
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        border-radius: 3px;
        width: 6px;
        background: ${theme.color.primary};
      }
    }
  `}
`

const ScALink = styled.a`
  text-decoration: none;
  font-size: 0.875rem;
  font-weight: bold;
  color: #fff;
  display: block;
  padding: 12px 32px 12px 48px;
  position: relative;
  transition: 0.2s;
  &:link,
  &:visited {
    color: #fff;
  }
  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
    text-decoration: none;
  }
`

const ScIcon = styled.div<StyleProps>`
  color: white;
  font-size: 20px;
  padding: 0 12px;
  text-align: ${({ isOpen }: StyleProps): string => (isOpen ? `right` : 'center')};

  svg {
    cursor: pointer;
  }
`

const Sidebar: React.FC<Props> = (props) => {
  const { pathname } = useLocation()
  const { handleToggleDrawer, isOpen } = props

  const { data: addonData } = useListCompanyAddonByCompanyId({})
  const addonItems = addonData?.listCompanyAddonV2s?.items || []

  const className = (pathNames: string[]): string => {
    return ClassNamesExport({
      'is-current': pathNames.includes(pathname)
    })
  }

  const authoriUrlType = getLastAuthoriSearchedUrlFromCookie()

  return (
    <ScSidebar role="navigation" isOpen={isOpen}>
      <ScIcon isOpen={isOpen}>
        <FontAwesomeIcon icon={isOpen ? faTimes : faBars} onClick={(): void => handleToggleDrawer(isOpen)} />
      </ScIcon>
      <ScSidebarInner isOpen={isOpen}>
        <CompanyStatusChecker serviceType="ON_SERVICE">
          <ScLabel>審査</ScLabel>
          <ScLink
            to={authoriUrlType || (AUTHORI_SEARCH_PATHS.find((e) => e.type === 'simple')?.path as string)}
            className={className([...AUTHORI_SEARCH_PATHS.map((e) => e.path)])}
          >
            審査検索
          </ScLink>
          <ScLink to="/authori/export" className={className(['/authori/export'])}>
            審査エクスポート
          </ScLink>

          <ScLabel>端末</ScLabel>
          <ScLink to={`/user_device?from=1&size=20&statusList=__INVALID`} className={className(['/user_device'])}>
            端末検索
          </ScLink>
          {/**
           * https://github.com/cacco-product/omoti-console/issues/745#issuecomment-1084260200
           * TODO 本番加盟店で結果返せてないので、隠す
           * https://github.com/cacco-product/omoti-console/issues/916
           * > BetaCheckerを使用に変更
           */}
          <BetaChecker>
            <ScLink
              to={`/user_device/users_calculation?thresholdStart=10&thresholdEnd=100`}
              className={className(['/user_device/users_calculation'])}
            >
              ユーザー数算出
            </ScLink>
            <ScLink
              to={`/user_device/device_calculation?thresholdStart=10&thresholdEnd=100`}
              className={className(['/user_device/device_calculation'])}
            >
              端末数算出
            </ScLink>
          </BetaChecker>
          <ScLabel>IP</ScLabel>
          <ScLink to="/ip/aggregation" className={className(['/ip/aggregation'])}>
            IP集計
          </ScLink>
          <ScLink to="/ip/aggregation/export" className={className(['/ip/aggregation/export'])}>
            IP集計エクスポート
          </ScLink>

          <ScLabel>分析</ScLabel>

          <ScLink
            to="/analytics/dashboard/time"
            className={className(['/analytics/dashboard/time', '/analytics/dashboard/day'])}
          >
            ダッシュボード
          </ScLink>
          <ScLink to="/analytics/report?reportType=day" className={className(['/analytics/report'])}>
            レポート
          </ScLink>
          {addonItems.length > 0 && (
            <>
              <ScLabel>アドオン</ScLabel>
              {addonItems.map(
                (item, i) =>
                  item && (
                    <ScLink key={i} to={`/addon/${item.addonId}`} className={className([`/addon/${item.addonId}`])}>
                      {item.name}
                    </ScLink>
                  )
              )}
            </>
          )}
          <ScLabel>サポート</ScLabel>
          <ScALink {...emmbedAnlTags('supportLink')} href={CONFIG.url} target="_blank">
            サポートセンター
          </ScALink>
          <UserRoleChecker roleTypes={ACCOUNT_TYPE_ARRAY.filter((e) => ['ADMIN'].includes(e))}>
            <ScLabel>加盟店設定</ScLabel>
            <ScLink to="/account" className={className(['/account'])}>
              アカウント一覧
            </ScLink>
            <BetaChecker>
              <ScLink to="/company/audit-log" className={className(['/company/audit-log'])}>
                監査ログ
              </ScLink>
            </BetaChecker>
            <ScLink to="/ip-report-setting" className={className(['/ip-report-setting'])}>
              IP集計レポート条件設定
            </ScLink>
            <CompanyAccountChecker>
              <BetaChecker>
                <ScLink to="/inquiries" className={className(['/inquiries'])}>
                  お問い合わせ
                </ScLink>
              </BetaChecker>
            </CompanyAccountChecker>
          </UserRoleChecker>
          <CompanyAccountChecker>
            <UserRoleChecker roleTypes={ACCOUNT_TYPE_ARRAY.filter((e) => ['OPE', 'VIEW'].includes(e))}>
              <BetaChecker>
                <ScLabel>加盟店設定</ScLabel>
                <ScLink to="/inquiries" className={className(['/inquiries'])}>
                  お問い合わせ
                </ScLink>
              </BetaChecker>
            </UserRoleChecker>
          </CompanyAccountChecker>
        </CompanyStatusChecker>
        <CompanyStatusChecker serviceType="COMPANYINSTALL">
          {/* TODO 加盟店導入の詳細 */}
          <ScLabel>サポート</ScLabel>
          <ScALink {...emmbedAnlTags('supportLink')} href={CONFIG.url} target="_blank">
            サポートセンター
          </ScALink>
        </CompanyStatusChecker>
      </ScSidebarInner>
    </ScSidebar>
  )
}

export default Sidebar
