import { css } from '@emotion/react'
import { theme } from './index'
import { utilityStyles } from './utility'

export const baseStyle = css`
  html {
    box-sizing: border-box;
    -webkit-text-size-adjust: 100%;
    word-break: break-word;
    -moz-tab-size: 4;
    tab-size: 4;
  }

  *,
  ::before,
  ::after {
    background-repeat: no-repeat;
    box-sizing: inherit;
  }

  ::before,
  ::after {
    text-decoration: inherit;
    vertical-align: inherit;
  }

  * {
    padding: 0;
    margin: 0;
  }

  /* # =================================================================
   # General elements
   # ================================================================= */

  hr {
    overflow: visible;
    height: 0;
  }

  details,
  main {
    display: block;
  }
  summary {
    display: list-item;
  }

  small {
    font-size: 80%;
  }

  [hidden] {
    display: none;
  }

  abbr[title] {
    border-bottom: none;

    text-decoration: underline;
    text-decoration: underline dotted;
  }

  a {
    background-color: transparent;
  }

  a:active,
  a:hover {
    outline-width: 0;
  }

  code,
  kbd,
  pre,
  samp {
    font-family: monospace, monospace;
  }

  pre {
    font-size: 1em;
    white-space: pre-wrap;
    word-wrap: break-word;
  }

  b,
  strong {
    font-weight: bolder;
  }

  /* https://gist.github.com/unruthless/413930 */
  sub,
  sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }

  sub {
    bottom: -0.25em;
  }

  sup {
    top: -0.5em;
  }

  /* # =================================================================
   # Forms
   # ================================================================= */

  input {
    border-radius: 0;
  }

  /* Replace pointer cursor in disabled elements */
  [disabled] {
    cursor: default;
  }

  [type='number']::-webkit-inner-spin-button,
  [type='number']::-webkit-outer-spin-button {
    height: auto;
  }

  [type='search'] {
    -webkit-appearance: textfield;
    outline-offset: -2px;
  }

  [type='search']::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  textarea {
    overflow: auto;
    resize: vertical;
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    font: inherit;
  }

  optgroup {
    font-weight: bold;
  }

  button {
    overflow: visible;
  }

  button,
  select {
    text-transform: none;
  }

  /* Apply cursor pointer to button elements */
  button,
  [type='button'],
  [type='reset'],
  [type='submit'],
  [role='button'] {
    cursor: pointer;
    color: inherit;
  }

  /* Remove inner padding and border in Firefox 4+ */
  button::-moz-focus-inner,
  [type='button']::-moz-focus-inner,
  [type='reset']::-moz-focus-inner,
  [type='submit']::-moz-focus-inner {
    border-style: none;
    padding: 0;
  }

  /* Replace focus style removed in the border reset above */
  button:-moz-focusring,
  [type='button']::-moz-focus-inner,
  [type='reset']::-moz-focus-inner,
  [type='submit']::-moz-focus-inner {
    outline: 1px dotted ButtonText;
  }

  button,
  html [type='button'],
  [type='reset'],
  [type='submit'] {
    -webkit-appearance: button;
  }

  /* Remove the default button styling in all browsers */
  button,
  input,
  select,
  textarea {
    background-color: transparent;
    border-style: none;
  }

  /* Style select like a standard input */
  select {
    -moz-appearance: none;
    -webkit-appearance: none;
  }

  select::-ms-expand {
    display: none;
  }

  select::-ms-value {
    color: currentColor;
  }

  legend {
    border: 0;
    color: inherit;
    display: table;
    max-width: 100%;
    white-space: normal;
    max-width: 100%;
  }

  ::-webkit-file-upload-button {
    -webkit-appearance: button;
    color: inherit;
    font: inherit;
  }

  /* # =================================================================
   # Specify media element style
   # ================================================================= */

  img {
    border-style: none;
    display: inline-block;
  }

  /* Add the correct vertical alignment in Chrome, Firefox, and Opera */
  progress {
    vertical-align: baseline;
  }

  svg:not([fill]) {
    fill: currentColor;
  }

  /* # =================================================================
   # Accessibility
   # ================================================================= */

  /* Hide content from screens but not screenreaders */
  @media screen {
    [hidden~='screen'] {
      display: inherit;
    }
    [hidden~='screen']:not(:active):not(:focus):not(:target) {
      position: absolute !important;
      clip: rect(0 0 0 0) !important;
    }
  }

  /* Specify the progress cursor of updating elements */
  [aria-busy='true'] {
    cursor: progress;
  }

  /* Specify the pointer cursor of trigger elements */
  [aria-controls] {
    cursor: pointer;
  }

  /* Specify the unstyled cursor of disabled, not-editable, or otherwise inoperable elements */
  [aria-disabled] {
    cursor: default;
  }
  html {
    font-size: ${theme.base.fontSize};
  }
  body {
    margin: 0;
    background-color: ${theme.base.backgroundColor};
    color: ${theme.font.color};
    font-family: ${theme.base.fontFamily};
    font-size: ${theme.font.size.medium};
    line-height: ${theme.base.lineHeight};
    word-wrap: break-word;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
  }

  body > iframe[style*='2147483647']:not([id='webpack-dev-server-client-overlay']) {
    display: none;
  }
  a {
    cursor: pointer;

    &:link,
    &:visited {
      color: #333;
      text-decoration: none;
    }

    &:hover {
      opacity: 0.9;
      text-decoration: underline;
    }
  }
  .linkText {
    cursor: pointer;
    color: #3143cf;
    text-decoration: underline;

    &:link,
    &:visited {
      color: #3143cf;
      text-decoration: underline;
    }

    &:hover {
      opacity: 0.9;
      text-decoration: none;
    }
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 800;
  }
  img {
    vertical-align: bottom;
  }
  hr {
    border-bottom: 0;
    border-top: #e6e6e6 1px solid;
  }

  input[type='radio'],
  input[type='checkbox'] {
    display: inline-block;
    margin-right: 6px;
  }

  input[type='radio'] + label,
  input[type='checkbox'] + label,
  label input[type='checkbox'] {
    position: relative;
    display: inline-block;
    margin-right: 12px;
    font-size: 12px;
    cursor: pointer;
  }

  input[type='radio'],
  input[type='checkbox'] {
    display: none;
    margin: 0;
  }

  input[type='radio'] + label,
  input[type='checkbox'] + label,
  label input[type='checkbox'] {
    padding: 0 24px 0 24px;
  }

  input[type='radio'] + label::before,
  input[type='checkbox'] + label::before,
  label input[type='checkbox']::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    display: block;
    width: 20px;
    height: 20px;
    background-color: white;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  input[type='radio'] + label::before {
    border: 2px solid #d2d6de;
    border-radius: 30px;
  }

  input[type='checkbox'] + label::before,
  label input[type='checkbox']::before {
    border: 2px solid #d2d6de;
    border-radius: 4px;
  }

  input[type='radio']:checked + label::after,
  input[type='checkbox']:checked + label::after,
  label input[type='checkbox']:checked::after {
    content: '';
    position: absolute;
    top: 50%;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    display: block;
  }

  input[type='radio']:checked + label::after {
    left: 6px;
    width: 8px;
    height: 8px;
    margin-top: -4px;
    background: #3143cf;
    border-radius: 50%;
  }

  input[type='checkbox']:checked + label::after,
  label input[type='checkbox']:checked::after {
    left: 4px;
    width: 12px;
    height: 6px;
    border-left: 2px solid #3143cf;
    border-bottom: 2px solid #3143cf;
    -webkit-transform: translateY(-50%) rotate(-45deg);
    transform: translateY(-50%) rotate(-45deg);
  }
  input[type='checkbox']:disabled,
  label input[type='checkbox']:disabled,
  input[type='checkbox']:disabled + label,
  label input[type='checkbox']:disabled:after {
    cursor: not-allowed;
  }

  .react-datepicker__navigation-icon {
    top: 7px !important;
  }

  ${utilityStyles()}
`
